import { ADMIN_RIDE_SETTINGS } from "../../constants/ActionTypes";

const INIT_STATE = {
    adminRideSetting: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADMIN_RIDE_SETTINGS: {

            return { ...state, adminRideSetting: action.payload };
        }
        default:
            return state;
    }
}