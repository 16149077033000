import { DEALER_NAME } from "../../constants/ActionTypes";

const INIT_STATE = {
    dealerName: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DEALER_NAME: {

            return { ...state, dealerName: action.payload };
        }
        default:
            return state;
    }
}