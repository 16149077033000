const iotCommands = [
    { label: 'Light On', command: 'lightOn', icon: 'LightOn', isPopUp: false, types: [1] },
    { label: 'Light Off', command: 'lightOff', icon: 'LightOff', isPopUp: false, types: [1] },
    { label: 'Alarm On', command: 'alarmOn', icon: 'AlarmOn', isPopUp: false, types: [1, 2] },
    { label: 'Alarm Off', command: 'alarmOff', icon: 'AlarmOff', isPopUp: false, types: [1, 2] },
    { label: 'Reconnect', command: 'track', icon: 'Track', isPopUp: false, types: [1] },
    { label: 'Get Location', command: 'location', icon: 'Locations', isPopUp: false, types: [1, 2] },
    { label: 'Max Speed', command: 'setMaxSpeed', icon: 'SetMaxSpeed', isPopUp: true, types: [1], key: 'maxSpeedLimit' },
    { label: 'Ping Interval', command: 'setPingInterval', icon: 'SetPingInterval', isPopUp: true, types: [1], key: 'pingInterval' },
    { label: 'Ride Ping Interval', command: 'setRidePingInterval', icon: 'SetRidePingInterval', isPopUp: true, types: [1], key: 'ridePingInterval' },
    { label: 'Unlock BatteryLock', command: 'unlockBatteryLock', icon: 'Batunlock', isPopUp: false, types: [1] },
];

export const NINEBOT_SCOOTER_US = [
    ...iotCommands
]

export const NINEBOT_SCOOTER_EU = [
    ...iotCommands
]

export const NINEBOT_TCP_SCOOTER = [
    { label: 'Light On', command: 'lightOn', icon: 'LightOn', isPopUp: false, types: [1] },
    { label: 'Light Off', command: 'lightOff', icon: 'LightOff', isPopUp: false, types: [1] },
    { label: 'Alarm On', command: 'alarmOn', icon: 'AlarmOn', isPopUp: false, types: [1, 2] },
    { label: 'Alarm Off', command: 'alarmOff', icon: 'AlarmOff', isPopUp: false, types: [1, 2] },
    { label: 'Reconnect', command: 'track', icon: 'Track', isPopUp: false, types: [1] },
    { label: 'Get Location', command: 'location', icon: 'Locations', isPopUp: false, types: [1, 2] },
    { label: 'Max Speed', command: 'setMaxSpeed', icon: 'SetMaxSpeed', isPopUp: true, types: [1], key: 'maxSpeedLimit' },
    { label: 'Ping Interval', command: 'setPingInterval', icon: 'SetPingInterval', isPopUp: true, types: [1], key: 'pingInterval' },
    { label: 'Ride Ping Interval', command: 'setRidePingInterval', icon: 'SetRidePingInterval', isPopUp: true, types: [1], key: 'ridePingInterval' },
    { label: 'BatteryLock Unlock', command: 'C0', commandValue: '0', icon: 'Batunlock', isPopUp: false, types: [1], isNewMethod: true },
    { label: 'CabelLock Unlock', command: 'L5', commandValue: '1', icon: 'CableLockUnlock', isPopUp: false, types: [1], isNewMethod: true },
    { label: 'Check BatteryLock Status', command: 'C0', commandValue: '2', icon: 'BatteryStatus', isPopUp: false, types: [1], isNewMethod: true },
    { label: 'Check CabelLock Status', command: 'L5', commandValue: '3', icon: 'CableStatus', isPopUp: false, types: [1], isNewMethod: true },
    { label: 'Obtain Scooter Information', command: 'S6', icon: 'CableStatus', isPopUp: false, types: [1], isNewMethod: true },
    { label: 'Throttle On', command: 'throttleOn', icon: 'Idleledon', isPopUp: false, types: [1] },
    { label: 'Throttle Off', command: 'throttleOff', icon: 'Idleledoff', isPopUp: false, types: [1] },
]