import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, ADMIN_RIDE_SETTINGS } from '../../constants/ActionTypes';
import axios from 'util/Api';
import { SETTING_TYPE } from '../../constants/Common';

export const getAdminSetting = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .post('admin/settings', { type: SETTING_TYPE.RIDE })
            .then((data) => {
                if (data.code === 'OK') {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: ADMIN_RIDE_SETTINGS, payload: data.data });
                } else {
                    console.log('payload: data.error', data.error);
                    dispatch({ type: FETCH_ERROR, payload: [] });
                }
            })
            .catch((error) => {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log('Error****:', error.message);
            });
    };
};