import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FRANCHISEE_NAME } from '../../constants/ActionTypes';
import axios from 'util/Api';

export const getFranchiseeName = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
            .post('admin/user/franchisee-list', {
                filter: {
                    addOwnUser: true,
                    isDeleted: false,
                    isActive: true
                }
            })
            .then((data) => {
                if (data.code === 'OK') {
                    let response = {};
                    response = data.data.list.reduce((val, cur) => (cur.id && { ...val, [cur.id]: cur.name }), {})

                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: FRANCHISEE_NAME, payload: response });
                    // dispatch({ type: FRANCHISEE_LIST_LENGTH, payload: response.length });
                } else {
                    console.log('payload: data.error', data.error);
                    dispatch({ type: FETCH_ERROR, payload: [] });
                }
            })
            .catch((error) => {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log('Error****:', error.message);
            });
    };
};