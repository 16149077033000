import antdKR from "antd/lib/locale-provider/ko_KR";
import appLocaleData from "react-intl/locale-data/ko";
import koMessages from "../locales/ko_KR.json";

const koLang = {
  messages: {
    ...koMessages
  },
  antd: antdKR,
  locale: 'ko',
  data: appLocaleData
};

export default koLang;