import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
import { unregister } from './registerServiceWorker';
// Add this import:
import { AppContainer } from 'react-hot-loader';

import { ApolloProvider } from "react-apollo";
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { split } from 'apollo-link';
import { BASE_URL } from "./constants/Setup";

let graphQLURL  = "";
let webSocektURL = "";
if (process.env.NODE_ENV === 'production') {
  // Set variable for production
  graphQLURL = ':' + '//graphql-' + BASE_URL.split(':')[1].split('//')[1];
  webSocektURL = `wss${graphQLURL}/custom-graphql-ws`;
} else {
  // Set variable for localhost
  graphQLURL = ':' + BASE_URL.split(':')[1] + ':4001';
  // graphQLURL = '://192.168.0.228:4001';
  webSocektURL = `ws${graphQLURL}/custom-graphql-ws`;
}
console.log('graphQLURL :>> ', graphQLURL);

const httpLink = createHttpLink({
  uri: BASE_URL.split(':')[0] + graphQLURL
})
const wsLink = new WebSocketLink({
  uri: webSocektURL,
  options: {
    reconnect: true
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <ApolloProvider client={client}>
        <NextApp />
      </ApolloProvider>
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
// registerServiceWorker();
unregister();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
